export default {
  entities: {
    store: {
      select: {
        labels: {
          store: "Do'kon",
        },
      },
      titles: {
        choose: 'Qiymatni tanlang',
      },
    },
  },
};
