import { createRouter, createWebHistory } from 'vue-router';
import OfflinePage from '@/modules/main/common/views/OfflinePage.vue';
import { useMainStore } from '@/modules/main/common/stores';
import { ROUTE_NAMES } from '@/modules/main/common/constants/routeNames';
import setDocumentTitle from '@/router/middleware/setDocumentTitle';
import requireAccessToAtLeastOneModule from '@/router/middleware/requireAccessToAtLeastOneModule';
import i18n from '@/plugins/i18n';
import requireAuthToken from '@/router/middleware/requireAuthToken';
import fetchAuthUser from '@/router/middleware/fetchAuthUser';
import fetchPrmApp from '@/router/middleware/fetchPrmApp';
import NProgress from 'nprogress';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: ROUTE_NAMES.home,
      component: () => import('@/modules/main/common/views/HomePage'),
      meta: {
        middlewares: [
          setDocumentTitle('Главная'),
          requireAuthToken,
          fetchAuthUser,
          fetchPrmApp,
          requireAccessToAtLeastOneModule,
        ],
      },
    },
    {
      path: '/no-permission',
      name: ROUTE_NAMES.noPermission,
      component: () => import('@/modules/main/common/views/ErrorPage'),
      meta: { middlewares: [setDocumentTitle('Недостаточно прав')] },
    },
    {
      path: '/module-access-denied',
      name: ROUTE_NAMES.moduleAccessDenied,
      component: () => import('@/modules/main/common/views/ErrorPage'),
      meta: { middlewares: [setDocumentTitle('Доступ к модулю запрещен')] },
    },
    {
      path: '/offline',
      name: ROUTE_NAMES.offline,
      component: OfflinePage,
      meta: { middlewares: [setDocumentTitle('Нет интернета')] },
    },
    {
      path: '/object-not-found',
      name: ROUTE_NAMES.objectNotFound,
      component: () => import(`@/modules/main/common/views/ErrorPage`),
      meta: { middlewares: [setDocumentTitle('Объект не найден')] },
    },
    {
      path: '/server-error',
      name: ROUTE_NAMES.serverError,
      component: () => import(`@/modules/main/common/views/ErrorPage`),
      meta: { middlewares: [setDocumentTitle('Ошибка сервера')] },
    },
    {
      path: '/:pathMatch(.*)',
      name: ROUTE_NAMES.pageNotFound,
      component: () => import(`@/modules/main/common/views/ErrorPage`),
      meta: { middlewares: [setDocumentTitle('Страница не найдена')] },
    },
    {
      path: '/clients/upload-file',
      name: ROUTE_NAMES.uploadClientFile,
      component: async () => {
        const { UploadClientFilePage } = await import('@/to-fsd/pages/upload-client-file');

        return UploadClientFilePage;
      },
      meta: {
        middlewares: [setDocumentTitle('Загрузить файл клиента')],
      },
    },
  ],
});

const visitedRoutes = new Set();

router.beforeEach((to, from, next) => {
  if (!visitedRoutes.has(to.name)) {
    NProgress.set(0.6);
    NProgress.start();
  }

  next();
});

router.afterEach(to => {
  if (!visitedRoutes.has(to.name)) {
    NProgress.done();
    visitedRoutes.add(to.name);
  }
});

const middlewarePipeline = (context, middleware, index) => {
  const nextMiddleware = middleware[index];

  if (!nextMiddleware) {
    return () => true;
  }

  return () => {
    const nextPipeline = middlewarePipeline(context, middleware, index + 1);

    return nextMiddleware({ ...context, next: nextPipeline });
  };
};

export let prevRoute = null;

router.beforeEach(async (to, from) => {
  prevRoute = from;
  const mainStore = useMainStore();

  if (!mainStore.lang) {
    mainStore.$patch({ lang: i18n.global.locale || 'uz' });
  }

  const context = {
    to,
    from,
  };

  const middlewares = to.meta?.middlewares || [];

  if (middlewares.length === 0) {
    return true;
  }

  return middlewares[0]({
    ...context,
    next: middlewarePipeline(context, middlewares, 1),
  });
});

export const routeErrorHandlers = [];

export const addRouteErrorHandler = handler => {
  routeErrorHandlers.push(handler);
};

export const removeRouteErrorHandler = handler => {
  const index = routeErrorHandlers.indexOf(handler);

  if (index !== -1) {
    routeErrorHandlers.splice(index, 1);
  }
};

router.onError(error => {
  routeErrorHandlers.map(handler => {
    handler(error);
  });
});

export default router;
