import { defineStore } from 'pinia';
import { actions } from './actions';
import { getters } from './getters';

export const useBalancesStore = defineStore('BalancesStore', {
  state: () => {
    return {
      topUpsStatuses: null,
      topUpsServices: null,
      stores: null,
      merchant: null,
      reports: null,
      cancelingIntervals: new Set(),
      topUps: [],
    };
  },
  actions,
  getters,
});
