<template>
  <div class="flex flex-col items-center justify-center min-h-screen max-w-full">
    <svg-offline class="w-full max-w-2xl"></svg-offline>
    <div class="mt-6 text-center">
      <h4 class="text-xl font-medium text-primary">{{ $t('system.errors.offline') }}</h4>
    </div>
  </div>
</template>

<script>
  import SvgOffline from '../components/SvgOffline';
  export default {
    name: 'OfflinePage',
    components: { SvgOffline },
  };
</script>

<script setup>
  import { onMounted, onUnmounted } from 'vue';

  import { tokenDecryption, uriToString } from '@/utils/filters';
  import router from '@/router';

  const onOnline = () => {
    const path = router.currentRoute.value?.query?.from
      ? tokenDecryption(uriToString(router.currentRoute.value?.query?.from))
      : '/';

    router.push({ path });
  };

  onMounted(() => {
    if (navigator.onLine) {
      onOnline();
    }

    window.addEventListener('online', onOnline);
  });

  onUnmounted(() => {
    window.removeEventListener('online', onOnline);
  });
</script>

<style scoped></style>
