export default {
  widgets: {
    warehouseItems: {
      filters: {
        search: {
          placeholder: 'Поиск...',
        },

        isVisible: {
          placeholder: 'Видимость в алифшоп',
          options: ['Выставленный', 'Не выставлен'],
        },

        hasOffer: {
          placeholder: 'Привязка к шаблону',
          options: ['Есть Шаблон', 'Нет Шаблона'],
        },

        quantity: {
          placeholder: 'Наличие товара',
          options: ['Имеется', 'Не имеется', 'До 5шт', 'До 10шт', 'До 15шт', 'До 20шт', 'До 50шт', 'Больше 50шт'],
        },

        reset: 'Сбросить',
      },
      showBy: 'Показывать по',
      headers: {
        items: 'Товары',
        status: 'Статус',
        sku: 'SKU',
        ikpu: 'ИКПУ',
        price: 'Цена (сум)',
        price_brutto: 'Цена с НДС',
        quantity: 'На складе',
        inAlifshop: 'В Алифшопе',
      },
      noOffer: 'Товар не привязан',

      selectedItemsActions: {
        title: 'Выбранные товары',
        list: ['Удалить', 'Выставить на продажу', 'Скрыть товары'],
      },
    },
  },
};
